import React from 'react'

import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import {
  createPluginFactory,
  insertNodes,
  PlateEditor,
  PlateRenderElementProps,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {ImVimeo} from 'react-icons/im'
import {MdClose} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

import useDebouncedEffect from '@/utils/use-debounced-effect'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {VimeoElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import {BlockButton} from '../toolbar/buttons'

export const VimeoRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)
  const editor = usePlateEditorState()!
  const path = ReactEditor.findPath(editor, element)

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: path})
  }, [editor, path])

  const el = element as VimeoElement

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none" width="100%">
      {children}
      <Box contentEditable={false} width="100%">
        {!!el.vimeoID && (
          <Vimeo video={el.vimeoID} responsive={true} width="100%" onError={(e) => console.log(e)} />
        )}
        {!readOnly && (
          <IconButton
            variant="outline"
            onClick={handleRemove}
            icon={<MdClose />}
            aria-label="Usuń film"
            position="absolute"
            top="5px"
            right="5px"
            zIndex={2}
          />
        )}
      </Box>
    </Flex>
  )
}

const vimeoRegexp = /^https:\/\/vimeo\.com\/([0-9]+)/

const getVimeoID = (value: string) => {
  const vimeoID = value.match(vimeoRegexp)

  if (!vimeoID?.length || vimeoID.length < 2) {
    return undefined
  }

  return vimeoID[1]
}

export const InsertVimeoButton = () => {
  const toast = useToast()

  const editor = usePlateEditorState()!
  const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()

  const [vimeoURL, setVimeoURL] = React.useState('')
  const [vimeoPreviewID, setVimeoPreviewID] = React.useState<string>()

  useDebouncedEffect(() => setVimeoPreviewID(vimeoURL.trim()), [vimeoURL], 250)

  const handleVimeoURLChange = React.useCallback(
    ({target: {value}}) => {
      setVimeoURL(value)
    },
    [setVimeoURL]
  )

  const modalFocusedRef = React.useRef<HTMLInputElement>(null)

  const handleToolbarButtonClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      setVimeoURL('')
      setVimeoPreviewID(undefined)
      onModalOpen()
    },
    [onModalOpen, setVimeoURL]
  )

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (!vimeoURL) {
        return
      }

      try {
        const vimeoID = getVimeoID(vimeoURL)
        if (!vimeoID) {
          throw new Error('Invalid Vimeo URL')
        }
        insertVimeo(editor, vimeoURL.trim())
      } catch (e) {
        console.error('Failed to insert Vimeo video', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się dodać filmu z Vimeo',
        })
      } finally {
        onModalClose()
      }
    },
    [editor, onModalClose, toast, vimeoURL]
  )

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={onModalClose} initialFocusRef={modalFocusedRef} size="lg">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader>Wstaw film z Vimeo</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing="5">
                <Input
                  ref={modalFocusedRef}
                  placeholder="Adres URL filmu"
                  variant="filled"
                  value={vimeoURL || ''}
                  onChange={handleVimeoURLChange}
                />

                {vimeoPreviewID && (
                  <Vimeo
                    width="100%"
                    responsive={true}
                    video={vimeoPreviewID}
                    onError={(e) => console.log(e)}
                  />
                )}
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onModalClose}>
                Anuluj
              </Button>
              <Button colorScheme="green" type="submit" disabled={!vimeoPreviewID}>
                Wstaw
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <BlockButton label="Vimeo" format="vimeo" icon={<ImVimeo />} onClick={handleToolbarButtonClick} />
    </>
  )
}

const insertVimeo = (editor: PlateEditor, vimeoID: string) => {
  const text = {text: ''}
  const video: VimeoElement = {
    children: [text],
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    type: 'vimeo',
    vimeoID,
  }
  insertNodes<VimeoElement>(editor, video)
}

export const createVimeoPlugin = createPluginFactory({
  component: VimeoRenderer,
  editor: {
    insertData: {
      format: 'text/plain',
      getFragment: ({data}) => [
        {
          children: [{text: ''}],
          id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
          type: 'vimeo',
          vimeoID: data.trim(),
        },
      ],
      query: ({data}) => !!data.match(vimeoRegexp)?.length,
    },
  },
  isElement: true,
  isVoid: true,
  key: 'vimeo',
  type: 'vimeo',
})
